  import React, {useEffect, useState} from "react";
// import { Route } from "react-router-dom";
// import { BrowserRouter, Route, Link } from "react-router-dom";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { useCookies } from "react-cookie";
import { confirmAlert } from "react-confirm-alert"; // Import

import { AxiosService } from "./service/axios.service";
import { AuthService } from "./service/auth.service";
import { UserService } from "./service/user.service";




import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import { Web } from "./web/index";

// import logo from "./logo.svg";
import "./App.css";

let auth = new AuthService();
// let userService = new UserService();

function App() {

  const [fixService, setFixService] = useState(false);

  const [cookie, setCookie, removeCookie] = useCookies(["session"]);
  // const [session, setSession] = useState(null);
  // const [session, setSession] = useState(null);

  let authenticated: boolean = cookie.session != null;
  let session: any = cookie.session != null;

  
  const getFixService = async() =>{
    const fix = await  auth.getFixService();
    if(fix.status === 'success'){
      setFixService(fix.isFixService as boolean)
    }
  }
  
  useEffect(() => {
    getFixService()
  });


  const login = (id: any, pw: any) => {

    auth.doLogin(id, pw).then((s) => {


      if (s.status === "success") {
        if (cookie.session != null) {
          removeCookie("session", { path: "/" });
        }
        setCookie("session", s.session, { path: "/" });
        setCookie("name", id, { path: "/" });

        window.location.href = "/";
      } else {

        confirmAlert({
          title: "로그인 ",
          message: "아이디 또는 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                removeCookie("session", { path: "/" });

                window.location.reload();
              },
            },
          ],
        });
      }
    });
  };

  const logout = () => {
    confirmAlert({
      title: "로그아웃 ",
      message: "로그아웃 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            removeCookie("session", { path: "/" });
          },
        },
      ],
    });
  };

  AxiosService.SetSession(cookie.session);

  
  if(fixService) {
    return (<div>
      <img src="https://d2ov46plj76ptt.cloudfront.net/public/notice/fixservice.png" style={{width:'100%', height:'100%'}}></img>
    </div>)
  }

  
  
  return (
    <>
      <Web
          tryLogin={login}
          authenticated={authenticated}
          session={session.session}
          tryLoginOut={() => {
            removeCookie("session", { path: "/" });
          }}
          SetCookie={(name: string, data: any) => setCookie(name, data, { path: "/" })}
          GetCookie={(name: string)=> ( cookie[name])}
          ></Web>
                 {
          authenticated && (
            document.write(`<script>doLiveChat("렌드-${cookie.name}")</script>`)
          )
        }
        {
          authenticated && (
            document.write(`<script
            src="https://kr03.tocplus007.com/chatLoader.do?userId=btsbts1111"
            type="text/javascript"
            ></script>`)
          )
        }
 
    </>
  );
}

export default App;
