import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import

import { BalanceService } from "../../service/balance.service";

import {
  ConverMoeny,
  popupView,
} from "../../utility/help";


import { ShareMenu } from "./share_menu";
import { ShareLeft } from "./share_left";

interface Props {
  user : any
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  rolling: number;
}

export class Comp extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {
        rolling: 0,
    };
  }


  

  handleExchangeToPoint = () => {
    this.balanceService.rolingToBalance(this.state.rolling).then((data : any) => {
      if (data.status === "success") {
        confirmAlert({
          title: "회원정보",
          message: "포인트가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
        return;
      } else if (data.status === "low_point") {
        confirmAlert({
          title: "회원정보",
          message: "1000포인트 이상부터 사용가능합니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원정보",
          message:
            "알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };



  render() {

    console.log(this.props.user)
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none"
        }}
        
        overlayStyle={{
          overflow: 'scroll'
        }}
      >
        {(close) => (
          
          <div>


<div className="modal-app show">
		<button className="modal-close-btn modal-close" data-dismiss="modal" onClick={() => {
                    this.props.handleClose();
                  }}></button>
		<a href="/" className="diamond-logo">
		   <img className="panel" src="/new/images/panel.png"/>
		   <img className="dias-lg" src="/new/images/diamond-lg.png"/>
		   <div className="dias-sm">
			  <img className="diamond-img" src="/new/images/diamond-sm.png"/>
			  <img className="glow" src="/new/images/glow.png"/>
		   </div>
		   <img className="diamond" src="/new/images/diamond.png"/>
		   <img className="slot" src="/new/images/slot.png"/>
		</a>

        <ShareMenu handleChange={this.props.handleChange}></ShareMenu>   


	
	 </div>

	 <div className="modal loginModal subpage-modal fade show" role="dialog" data-backdrop="static" aria-modal="true" style={{paddingRight:'17px',display:'block'}}>
		<div className="modal-dialog modal-dialog-centered">
		   <div className="modal-content">
			  <div className="row">

                  <ShareLeft handleChange={this.props.handleChange} viewType={popupView.comp}></ShareLeft>

				 <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
					<div className="modal-body">
					   <div className="modal-title">
						  <h6 className="title mb-0">포인트</h6>
						  <h6 className="sub ml-2 mb-0">Point</h6>
					   </div>
            
             <div className="form-container">
              <div className="form-group">
                <div className="input-container">
                  <div className="infos">
                  {
                    this.props.user && 
                    <span >보유 포인트 : {ConverMoeny(this.props.user.rolling)}</span>
                  }

                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <div className="form-group">
                <div className="labels">
                    <span>전환 포인트</span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    <input className="form-control parsley-success" value={this.state.rolling} onChange={(e) => this.setState({rolling: Number(e.target.value),})} />
                  </div>
                </div>
              </div>
            </div>    

            <div className="modal-footer">
              <button type="submit" className="btn-style" onClick={this.handleExchangeToPoint}>변환하기</button>
            </div> 

     
            
          
					</div>
				 </div>
			  </div>
		   </div>
		</div>
	 </div>


   <div className="modal-backdrop fade show"></div>

          </div>

        )}
      </Popup>
    );
  }
}
